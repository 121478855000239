export const strategyAccessMixin = {
  computed: {
    userProfileMix () {
      return this.$store.getters.getUserProfile
    },
    userHasAccessToStrategiesMix() {
      if (this.userProfileMix.hasStrategyAccess) {
        return true
      }
      return false
    }
  },
  methods: {
  },
  async created () {
    if (!this.$store.getters.getUserProfile) await this.$store.dispatch('getUserProfile') // Needed for above functions
  }
}