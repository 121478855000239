<template>
  <TheNavbar />

  <div class="mt-5 mb-5">

    <div class="row justify-content-center mb-4">

      <div class="col-12 text-center">
        <h3>Ichimoku Strategy (4h)</h3>
      </div>

      <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6 mt-3">

        <div class="card">
          <div class="card-header">
            <div class="row flex-between-end">
              <div class="col-auto text-center">
                <h5 class="mb-0" data-anchor="data-anchor">Get access now</h5>
              </div>
            </div>
          </div>
          <div class="card-body bg-body-tertiary">
            <p>
              Enter your TradingView username (e.g. tothemoon123) below to get access to this strategy. It needs your username not your email!
            </p>

            <StrategyGetAccessForm :strategyId="'ICHIMOKU_EMA_4H'" />

          </div> <!-- card-body-->
        </div>

      </div>
    </div>

    <div class="row justify-content-center mb-3">
      <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6">

        <div class="card">
          <div class="card-header">
            <div class="row flex-between-end">
              <div class="col-auto text-center">
                <h5 class="mb-0" data-anchor="data-anchor">How to find your TradingView username</h5>
              </div>
            </div>
          </div>
          <div class="card-body bg-body-tertiary">
            <p>
              Click on your profile icon on the top left...<br>
              <img class="img-fluid rounded mx-auto mb-3" src="@/assets/strategies/tv_username.jpg" />
            </p>
          </div> <!-- card-body-->
        </div>

      </div>
    </div>
    
    <div class="row justify-content-center mb-3">
      <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6">

        <div class="card">
          <div class="card-header">
            <div class="row flex-between-end">
              <div class="col-auto text-center">
                <h5 class="mb-0" data-anchor="data-anchor">What to do next</h5>
              </div>
            </div>
          </div>
          <div class="card-body bg-body-tertiary">
            <p>
              <span class="fw-bold">Once you have access (confetti was flying), follow these steps:</span>
            </p>

            <ol>
              <li>Open Strategy: <a href="https://www.tradingview.com/script/xo3Z4elt-Ichimoku-Strategy-v2-1/" target="_blank">https://www.tradingview.com/script/xo3Z4elt-Ichimoku-Strategy-v2-1/</a></li>
              <li>Scroll down and click on "Add to favorites"</li>
              <li>Reload TradingView (F5 or CMD+R)</li>
              <li>Go back to your TradingView chart</li>
              <li>Click on Indicators in the top menu</li>
              <li>You'll now find it in your Favorites</li>
              <li>Click on it one-time to add it to your chart</li>
              <li>Done :)</li>
            </ol>

            <p>
              <span class="fw-bold">Watch this tutorial video to learn how this strategy works and how to automate it:</span>
            </p>
            <iframe width="100%" height="400" style="max-width: 800px;" src="https://www.youtube.com/embed/dfsWBx6macw?si=AXcyoaXNryqlFuqA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

          </div> <!-- card-body-->
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import TheNavbar from '@/components/TheNavbar'
import StrategyGetAccessForm from '@/components/StrategyGetAccessForm'
import { strategyAccessMixin } from '@/mixins/strategyAccessMixin'

export default {
  mixins: [strategyAccessMixin],
  components: {
    TheNavbar,
    StrategyGetAccessForm
  },
  async created () {
    if (!this.$store.getters.getUserBots) await this.$store.dispatch('getUserBots')
  },
  beforeMount () {
    // Redirect to Login when the user is not logged in
    if (!this.$store.getters.isUserLoggedIn) this.$router.push({ name: 'Login' })

    // Redirect when the user does not have access to SIGNUM Strategies
    if (!this.userHasAccessToStrategiesMix) this.$router.push({ name: 'Strategies' })
  }
}
</script>