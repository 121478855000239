<template>

  <p>This chatbot has been trained with all our tutorial videos, all questions our users ever asked, the answers and also with custom input we added to cover more cases.<br>
    We hope it is helpful to you 🥳<br>
    <br>
    If you require additional help, please come to our <a :href="getSupportUrl()" target="_blank">Telegram Group</a> 💬 where we help thousands of users 24/7 with admins from all over the world.</p>
  <iframe
  src="https://app.wonderchat.io/chatbot/cm6cqz79s0aesznybuy3blfvu"
    style="border-width: 0px; color-scheme: auto; background: transparent;"
    allow="clipboard-read; clipboard-write"
    allowtransparency="true"
    width="100%"
    height="700"
  ></iframe>

</template>

<script>
export default {
  methods: {
    getSupportUrl () {
      return process.env.VUE_APP_SUPPORT_URL
    }
  },
}
</script>

<style scoped>

</style>