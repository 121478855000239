export const userHelperMixin = {
  computed: {
    userProfileMix () {
      return this.$store.getters.getUserProfile
    },
    userMaxBotsMix () {
      if (this.userProfileMix.isAutotradingUser) {
        return process.env.VUE_APP_DEFAULT_MAX_BOTS_AT_USERS
      }

      // TODO: Users we've given 50 Bots because we don't yet have a subscription for that. This will disappear and they will need tp pay for it once we do have.
      // 17 = signum-demo@autotrading.vip (for testing purposes)
      // 4961 = sebastian.herzog.1983@gmail.com
      // 32 = cjsgrilljosh@gmail.com (Josh Admin)
      // 3371 = flumperdump@hotmail.com (Sweman Admin)
      // 2764 = rohani_t@yahoo.com (Traneh Admin)
      // 1980 = signum-account-2.immovable569@passinbox.com (Grum Admin)
      // 551 = signum.only195@passinbox.com (Grum Admin)
      // 2669 = cameronhutcheon4@gmail.com (Spanky Admin)
      if ([17, 4961, 32, 3371, 2764, 1980, 551, 2669].includes(this.userProfileMix?.id)) {
        return 50
      }

      return process.env.VUE_APP_DEFAULT_MAX_BOTS
    }
  },
  methods: {
  },
  async created () {
    if (!this.$store.getters.getUserProfile) await this.$store.dispatch('getUserProfile') // Needed for above functions
  }
}