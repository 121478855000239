<template>
  <div v-if="botLogs.items" class="table-responsive scrollbar">
    <table class="table table-hover table-sm table-striped fs-10 mb-0 overflow-hidden">
      <thead class="bg-200">
        <tr>
          <th class="text-900 pe-1 align-middle white-space-nowrap">Your Local Time</th>
          <th class="text-900 pe-1 align-middle white-space-nowrap">Bot Title</th>
          <th class="text-900 pe-3 ps-3 align-middle white-space-nowrap text-center">Action</th>
          <th class="text-900 pe-3 ps-3 align-middle white-space-nowrap text-center">Type</th>
          <th class="text-900 pe-1 align-middle white-space-nowrap" style="min-width: 12.5rem;">Message</th>
        </tr>
      </thead>
      <tbody class="list" id="table-orders-body">
        <tr v-if="botLogs.items?.length <= 0" class="">
          <td colspan="5" class="order py-2 align-middle white-space-nowrap text-truncate text-center">
            <span>No logs found</span>
          </td>
        </tr>
        <tr v-for="botLog in botLogs.items" :key="botLog.id" class="">
          <td class="order pe-3 py-2 align-middle white-space-nowrap text-truncate">
            <span class="fw-bold">{{ getPrettyDateText(botLog.timestamp) }}</span>
          </td>
          <td class="order py-2 align-middle white-space-nowrap text-truncate">
            
            <router-link v-if="getBotById(botLog.botId)" :to="{name: 'EditBot', params: {id: botLog.botId}}">
              <span class="fw-bold">{{ getBotTitleText(botLog.botId) }}</span>
            </router-link>
            <span v-else class="fw-bold">{{ getBotTitleText(botLog.botId) }}</span>

          </td>
          <td class="date py-2 align-middle text-center">
            <span class="fw-bold">{{ getLogAction(botLog.message) }}</span>
          </td>
          <td class="date py-2 align-middle text-center">
            <span :class="getLogLevelClassName(botLog.logLevel)">{{ botLog.logLevel }}</span>
          </td>
          <td class="address py-2 align-middle white-space-nowrap text-truncate">
            <span :class="getLogLevelClassName(botLog.logLevel)" class="">{{ getFormattedLogMessage(botLog.message) }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    botLogs: {
      required: true,
      type: Object
    },
    bots: {
      required: true,
      type: Array
    }
  },
  methods: {
    getBotById (id) {
      return this.bots.find(elem => elem.id == id)
    },
    getBotTitleText (id) {
      const bot = this.getBotById(id)
      if (bot) {
        return bot.title
      }

      return "-"
    },
    getPrettyDateText (someString) {
      const timestamp = Date.parse(someString);
      const dateObj = new Date(timestamp)
      return dateObj.toLocaleString(undefined, {year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', hour12: false, minute:'2-digit', second:'2-digit'})
    },
    getLogAction (message) {
      if (message.search('action: buy') > 0) return "Buy"
      if (message.search('action: sell') > 0) return "Sell"

      return ""
    },
    getFormattedLogMessage (message) {
      return message.charAt(0).toUpperCase() + message.slice(1)
    },
    getLogLevelClassName (logLevel) {
      if (logLevel === 'error'){
        return 'text-danger'
      } 
      
      if (logLevel === 'warning'){
        return 'text-warning'
      }

      return ''
    }
  }
}
</script>

<style scoped>

</style>