<template>
  <TheNavbar />

  <div class="mt-5 mb-5">

    <div class="row justify-content-center g-3">

      <div class="col-12 text-center">
        <h3>SIGNUM Strategies</h3>
        <p>You have full access to these strategies, while you have an active SIGNUM account (active trial or active subscription). When your account becomes inactive, you automatically lose access to these strategies.</p>
      </div>

      <div v-if="!userHasAccessToStrategiesMix" class="col-12">
        <div class="alert alert-warning border-0 d-flex align-items-center" role="alert">
          <div class="bg-warning me-3 icon-item"><span class="fas fa-exclamation-circle text-white fs-6"></span></div>
          <p class="mb-0 flex-1">
            <span class="fw-bold">You don't have access to the SIGNUM Strategies anymore 😱</span><br>
            Your access is free for the first 60 days after you've signed up or during your trial period, which is 14 days after the 1st trade you've made. Both have expired in your case.<br>
            <br>
            Subscribe now to regain access:<br>
            <router-link :to="{name: 'Subscription', params: {}}" class="btn btn-primary btn-sm mt-1" type="button">
              Subscribe Now
            </router-link>
          </p>
        </div>
      </div>

      <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6" :class="userHasAccessToStrategiesMix ? 'opacity-100' : 'opacity-25'">
        <div class="card h-100">
          <div class="card-header">
            <div class="row flex-between-end">
              <div class="col-auto text-center">
                <h5 class="mb-0" data-anchor="data-anchor">Gaussian Channel Strategy (1D)</h5>
              </div>
            </div>
          </div>
          <div class="card-body bg-body-tertiary">
            <img class="img-fluid rounded mx-auto mb-3" src="@/assets/strategies/gaussian_channel_chart.jpg" />

            <p>
              This strategy is private. You can get access to it by clicking on "Get Access" below.
            </p>

            <div class="text-center">
              <a class="btn btn-primary btn-sm" type="button" href="https://www.tradingview.com/script/TUxD9OoE-Gaussian-Channel-Strategy-v3-0/" target="_blank">
                Open Strategy <span class="fas fa-external-link-alt" data-fa-transform="shrink-3 down-0"></span>
              </a>
              &nbsp;

              <router-link v-if="userHasAccessToStrategiesMix" :to="{name: 'GaussianChannelStrategy', params: {}}" class="btn btn-primary btn-sm" type="button" href="">
                Get Access
              </router-link>
              <a v-if="!userHasAccessToStrategiesMix" class="btn btn-primary btn-sm" type="button"> Get Access </a>
            </div>
          </div>
        </div> <!-- card-->
      </div> <!-- col-->

      <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6" :class="userHasAccessToStrategiesMix ? 'opacity-100' : 'opacity-25'">
        <div class="card h-100">
          <div class="card-header">
            <div class="row flex-between-end">
              <div class="col-auto text-center">
                <h5 class="mb-0" data-anchor="data-anchor">Ichimoku Strategy (4h)</h5>
              </div>
            </div>
          </div>
          <div class="card-body bg-body-tertiary">
            <img class="img-fluid rounded mx-auto mb-3" src="@/assets/strategies/ichimoku_ema_4h.jpg" />

            <p>
              This strategy is private. You can get access to it by clicking on "Get Access" below.
            </p>

            <div class="text-center">
              <a class="btn btn-primary btn-sm" type="button" href="https://www.tradingview.com/script/xo3Z4elt-Ichimoku-EMA-Strategy-4h-v2-1/" target="_blank">
                Open Strategy <span class="fas fa-external-link-alt" data-fa-transform="shrink-3 down-0"></span>
              </a>
              &nbsp;

              <router-link v-if="userHasAccessToStrategiesMix" :to="{name: 'PageIchimokuEma4hStrategy', params: {}}" class="btn btn-primary btn-sm" type="button" href="">
                Get Access
              </router-link>
              <a v-if="!userHasAccessToStrategiesMix" class="btn btn-primary btn-sm" type="button"> Get Access </a>
            </div>
          </div>
        </div> <!-- card-->
      </div> <!-- col-->

      <div class="col-12 text-center mt-5">
        <h3>Public Strategies</h3>
        <p>These strategies are publicly available and don't need SIGNUM or special permissions to use. We picked these from hundreds of strategies we analyzed. Enjoy :)</p>
      </div>
      
      <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6">
        <div class="card h-100">
          <div class="card-header">
            <div class="row flex-between-end">
              <div class="col-auto text-center">
                <h5 class="mb-0" data-anchor="data-anchor">Hull Suite Strategy (1D)</h5>
              </div>
            </div>
          </div>
          <div class="card-body bg-body-tertiary">
            <img class="img-fluid rounded mx-auto mb-3" src="@/assets/strategies/hull_suite_chart.jpg" />

            <p>
              This strategy is public, so you can use it without asking for access.
            </p>

            <div class="text-center">
              <a class="btn btn-primary btn-sm" type="button" href="https://www.tradingview.com/script/Q9OQye4C-Hull-Suite-Strategy/" target="_blank">
                Open Strategy <span class="fas fa-external-link-alt" data-fa-transform="shrink-3 down-0"></span>
              </a>
            </div>
          </div>
        </div> <!-- card-->
      </div> <!-- col-->

      <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6">
        <div class="card h-100">
          <div class="card-header">
            <div class="row flex-between-end">
              <div class="col-auto text-center">
                <h5 class="mb-0" data-anchor="data-anchor">Ichimoku TK Cross > EMA200 Crypto Strategy (1D)</h5>
              </div>
            </div>
          </div>
          <div class="card-body bg-body-tertiary">
            <img class="img-fluid rounded mx-auto mb-3" src="@/assets/strategies/ichimoku_tk_cross.jpg" />

            <p>
              This strategy is public, so you can use it without asking for access.
            </p>

            <div class="text-center">
              <a class="btn btn-primary btn-sm" type="button" href="https://www.tradingview.com/script/88TwdvFZ-Ichimoku-TK-Cross-EMA200-Crypto-Strategy/" target="_blank">
                Open Strategy <span class="fas fa-external-link-alt" data-fa-transform="shrink-3 down-0"></span>
              </a>
            </div>
          </div>
        </div> <!-- card-->
      </div> <!-- col-->

      <div class="col-12 text-center mt-5">
        <h3>Help & Education</h3>
      </div>

      <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6">
        <div class="card">
          <div class="card-header">
            <div class="row flex-between-end">
              <div class="col-auto text-center">
                <h5 class="mb-0" data-anchor="data-anchor">How to use these strategies?</h5>
              </div>
            </div>
          </div>
          <div class="card-body bg-body-tertiary">
            <img class="img-fluid rounded" style="max-height: 40px;" src="@/assets/strategies/open_tv_strategy.jpg" />
            <p>
              Open the strategy you want to use.
            </p>

            <img class="img-fluid rounded" style="max-height: 40px;" src="@/assets/strategies/add_to_fav.jpg" />
            <p>
              Scroll down and add the strategy to your favorites.
            </p>

            <img class="img-fluid rounded mx-auto" src="@/assets/strategies/tv_indicators.jpg" />
            <p>
              <a href="https://www.tradingview.com/chart/" target="_blank">Go to TradingView <span class="fas fa-external-link-alt" data-fa-transform="shrink-3 down-0"></span></a> and open the <span class="fw-bold">Indicators</span> menu.<br>
              Click on the strategies you want to add to your chart (click once per strategy).<br>
              <br>
              Now go to the <router-link :to="{name: 'MyBots', params: {}}" class="" href="">SIGNUM Bot</router-link> that you want to use (or create one) and follow the instructions there :)
            </p>


          </div>
        </div> <!-- card-->
      </div> <!-- col-->

      <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6">
        <div class="card">
          <div class="card-header">
            <div class="row flex-between-end">
              <div class="col-auto text-center">
                <h5 class="mb-0" data-anchor="data-anchor">AutoTrading Masterclass</h5>
              </div>
            </div>
          </div>
          <div class="card-body bg-body-tertiary">
            <img class="img-fluid rounded mx-auto mb-3" src="@/assets/strategies/at_course_cover.jpg" />

            <p>
              Learn how to automate your Bitcoin & Crypto investments with algo trading strategies. Become a master in risk & portfolio management and trade automation.<br>
              Get access to the AutoTrading trading strategies, the community and 12 months free SIGNUM access.
            </p>

            <div class="text-center">
              <a class="btn btn-primary btn-sm" type="button" href="https://autotrading.vip" target="_blank">
                Learn More <span class="fas fa-external-link-alt" data-fa-transform="shrink-3 down-0"></span>
              </a>
            </div>
          </div>
        </div> <!-- card-->
      </div> <!-- col-->
      
    </div>

  </div>
</template>

<script>
import TheNavbar from '@/components/TheNavbar'
import { strategyAccessMixin } from '@/mixins/strategyAccessMixin' // https://www.vuemastery.com/courses/next-level-vue/mixins/

export default {
  mixins: [strategyAccessMixin],
  components: {
    TheNavbar
  },
  computed: {
    getSupportUrl () {
      return process.env.VUE_APP_SUPPORT_URL
    }
  },
  methods: {
    
  },
  async created () {
    if (!this.$store.getters.getUserBots) await this.$store.dispatch('getUserBots')
  },
  beforeMount () {
    // Redirect to Login when the user is not logged in
    if (!this.$store.getters.isUserLoggedIn) this.$router.push({ name: 'Login' })
  }
  
}
</script>